// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/NAVIGATION
// --------------------------------------------------

// VARIABLES
  $color-navigation-main--active:   	$color-brand;
  $color-navigation-sub:              $color-blue;
  $color-navigation-sub--active:      $color-blue;
  $nav--main__item-padding:         	$base-spacing-unit--sm;
  $nav--main__item-padding--sm:     	$base-spacing-unit--xs;
  $nav--main-font:                  	$base-font-family-headers;
  $nav--main-font-weight:           	700;
  
  $submenu-background:								$base-overlay-background;
  
  $nav-mobile-color-background:       $base-overlay-background;
  $nav-mobile-number-of-menu-points:  4;
  
  $menu-icon-width:										1.25rem;
  $menu-icon-stroke:									2px;
  

// USED FROM _variables.scss
// $color-text--inverted
// $base-spacing-unit, --xs, --sm
// $base-border
// $base-font-family-headers

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// --------------------------------------------------

@import "../../../nutshell/scss/components/navs";

// --------------------------------------------------

// Main Navigation

// [1] IE9 needs this to make the whole anchor clickable and lets the dropdown-navigation work
// [2] 9999px make sure, the max-height is bigger than the actual submenu height, percentage will reference the header height
.nav--main {
  line-height: 1em;
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  
  a,
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: $nav--main__item-padding;
    text-transform: uppercase;
    text-decoration: none;
    
    @include media-query(screen-offcanvas) {
	    padding: calc((#{$header-height} - 1em) / 4) $nav--main__item-padding;
	    margin: calc((#{$header-height} - 1em) / 4) $nav--main__item-padding 0;
    }
    
    @if ($ie9-support == true) {
	    background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // [1]
    }
  }
  
  a {
    color: inherit;
		&:hover {
			color: $color-navigation-main--active;
		}
  }
  
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    color: $color-navigation-main--active;
  }
  
  .level_2 {
	  z-index: 1001;
	  font-size: 0.85rem;
	  overflow: hidden;
	  
	  @include media-query(screen-offcanvas) {
		  max-height: 0;
		  opacity: 0;
		  position: absolute;
		  padding: 0 $nav--main__item-padding;
		  transition: opacity 0.2s 0.3s, max-height 0s 0.6s;
	  }
	  
	  > li {
		  float: none;
		  padding: 0;
		  
		  @include media-query(screen-offcanvas) {
		  	background: $submenu-background;
		  }
	  }
	  
	  @include media-query(screen-offcanvas) {
		  > li.first {
			  padding-top: $nav--main__item-padding--sm;
		  }
		  
		  > li.last {
			  padding-bottom: $nav--main__item-padding--sm;
		  }
		  
		  a {
        color: $color-navigation-sub;
        border-bottom: 1px solid $base-overlay-background;
				&:hover {
          color: $color-navigation-sub--active;
          border-bottom: 1px solid currentColor;
				}
		  }
		}
	  
	  a,
	  span.active,
	  span.trail,
	  strong.active,
	  strong.trail {
	    padding: $nav--main__item-padding--sm $nav--main__item-padding;
      margin: 0;
	  }
  }
  
  li.submenu:hover .level_2 {	  
	  z-index: 1004;
	  max-height: 9999px;
	  opacity: 1;
	  transition-delay: 0s;
	}
	
	.level_3 {
		@include media-query(screen-offcanvas) {
			margin-left: $base-spacing-unit--sm;
		}
		
		li {
			float: none;
		}
		
		a,
		span.active,
		span.trail,
	  strong.active,
	  strong.trail {
			text-transform: none;
		}
	}
}

// [1] transition for mobile navigation fade in background
// [2] transition for mobile navigation item moving
// [3] remove scrollbar for IE9 as it doesn't support -ms-overflow-style
.nav--mobile {
  @include media-query(screen-offcanvas-max) {
    max-height: 0;
    visibility: hidden;
    transition: max-height 0s 3s, background 0.3s; // [1]
    background: transparent; // [1]
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -1;
    visibility: visible;
    font-size: 1.5rem;
    text-shadow: 0.025rem 0.025rem 2rem $nav-mobile-color-background;
    
    > ul {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      max-height: 100%;
      padding-top: $header-height;
      
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-touch-action: touch;
      -ms-overflow-style: none;
    }
    
    > ul > li {
    transition: transform 0.3s, opacity 0.3s; // [2]
    opacity: 0; // [2]
    
      @include media-query(screen-offcanvas) {
        opacity: 1;
      }
    }
    
    a {
	    color: $color-text--inverted;
    }
  }
  
  .ie9 & {
	  > ul {
		  overflow-y: auto;	// [3]
	  }
  }
}

.nav--mobile--active {
  @include media-query(screen-offcanvas-max) {
    max-height: 100%;
    transition: max-height 0s, background 0.3s; // [1]
    background: $nav-mobile-color-background;
  
    >ul > li {
      transform: translateY(-#{$base-spacing-unit--sm});
      opacity: 1;
      
      @for $sec from 1 through $nav-mobile-number-of-menu-points {
        &:nth-child(#{$sec}) {
          transition-delay: #{$sec * 1 / 8}s;
        }
      }
    }
  }
}

// button to display mobile navigation
.nav-toggler {
  font-family: sans-serif;
  float: right;
  position: absolute;
  top: 0;
  right: $grid__gutter--half; 
  height: $header-height;

  @include media-query(screen-offcanvas) {
    display: none;  
  }   
}

.nav-toggler__button {
	@include vertical-align(50%);
  
  background: none;
  border: $base-border;
  outline: none; 
  font-weight: 700;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  color: currentColor;
  padding: $base-spacing-unit--xs/2 $base-spacing-unit--xs;
}

.menu-icon {
	position: relative;
	width: $menu-icon-width;
	height: $base-line-height * 1rem;
	display: inline-block;
	vertical-align: top;
}

.menu-icon__inner {
	width: 100%;
	background-color: currentcolor;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: $menu-icon-stroke/-2;
  height: $menu-icon-stroke;
  transition: transform 0.15s ease;

	&:after, 
	&:before {
	  background-color: currentcolor;
	  position: absolute;
	  display: block;
		content: "";
    width: 100%;
		height: $menu-icon-stroke;
    transition: transform 0.15s ease, opacity 0.15s;
	}
	
	&:before {
		top: -6px;
	}
	
	&:after {
	  bottom: -6px;
	}
	
	.html--fixed & {
		transform: rotate(45deg);
		
		&:before {
			opacity: 0;
		}
		
		&:after {
			transform: rotate(-90deg);
			bottom: 0;
		}
	}
}

.nav--meta {
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;
  text-align: center;
  
  @include media-query(screen-offcanvas) {
  	float: left;
  }
  
  li {
	  display: inline-block;
  }
  
  a,
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: 0 $base-spacing-unit--sm;
    text-decoration: none;
    
    &.first {
      padding-left: 0;
    }
  }
}

.pagination {
  font-family: $base-font-family-headers;
  font-weight: bold;
}
