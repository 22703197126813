// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/SOCIAL
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.social {
  text-align: center;
}

.social__icons {
  font-size: 2rem;
  
  font-family: $base-font-family-headers;
  font-weight: 900;
  display: inline-block;
  padding-left: $base-spacing-unit;
  padding-right: $base-spacing-unit;
  
  a {
    color: inherit;
    
    &:hover {
      color: $color-brand;
    }
  }
}
