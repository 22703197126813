// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES
$news-grid-columns--sm: 2;
$news-grid-columns--md: 2;
$news-grid-columns--lg: 3;

$news-hero-background-color: $color-band-dark-background;

// USED FROM _variables.scss
// $base-spacing-unit, --xs, --lg 
// $base-font-family-headers
// 

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// USED FROM _type.scss
// $headings__font-family
// $headings__font-weight

// --------------------------------------------------

@import "../../../nutshell/scss/components/news";

// --------------------------------------------------

// [1] override link color to text-color
// [2] images will only be shown in .layout_latest
.news-list {
  > { 
    #{headings()} {
      @extend %h5;
      
      &:after {
        margin-top: $base-spacing-unit--xs;
      }
    }
  }
  
  .layout_simple {
    position: relative;
    font-family: $base-font-family-headers;
    
    @extend %h3;
    
    time {
      display: block;
      font-size: 1rem;
      
      & + a {
        padding-top: 0;
      }
    }
    
    a {
      padding-right: $base-spacing-unit;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      
      &:after {
        content: "»";
        position: absolute;
        right: 0;
        padding-right: ($base-spacing-unit / 2);
      }
    } 
  }
  
	.layout_short,
	.layout_latest {
		.info {
		  margin-top: $base-spacing-unit;
		  font-family: $base-font-family-headers;
		}
		
		#{headings()} {
		  margin-top: 0;
		  
		  a {
        color: inherit; // [1]
      }
		}
	}
  
  .layout_latest {
	  .info {
		  display: inline-block;
	  }
	  
	  .teaser {
		  overflow: hidden;
	  }
  }  
  
  // .layout_latest // [2]
  .image_container { 
	  margin-top: 0;
	  margin-left: 0;
	  margin-right: 0;
	  float: none;
	  
	  @include media-query(screen-md) {
		  float: left;
		  margin-top: $base-spacing-unit;
			margin-right: $base-spacing-unit;
			margin-left: 0;
			max-width: percentage(6/12);
	  }
	  
	  img {
		  margin-left: 0;
	  }
  }
}

// [1] IE11: remove uncommon space between image and headline
.news-grid {
  
  .layout_short,
  .layout_latest,
  .layout_simple {
    
    #{headings()} {
      @extend %h3;
    
      a {
        color: inherit;
      }
    }
    
    &:after {
      border-bottom: $base-border;
      margin-top: $base-spacing-unit--sm;
      content: "";
      display: block;
    }

    // Datum und Uhrzeit
    p {
      font-weight: 700;
      font-size: 1rem;
    }
  }
  
  .image_container {
    //margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;
    float: none;
    overflow: hidden; // [1]
  }
  
  .image_container + h2 {
    margin-top: 0;
  }
  
  .info {
    padding-top: $base-spacing-unit--sm;
    font-size: 0.85em;
    font-weight: 700;
    font-family: $base-font-family-headers;
    color: $color-gray;
  }
  
  .pagination {
    @include padding-default;
  }
  
  display: flex;
  flex-wrap: wrap;
  
  .layout_short,
  .layout_latest {
    display: flex;
    flex-direction: column;
  }
  
  .info {
    margin-top: auto;
  }
}

// [1] calculate height based on padding for mod_article, including fallback
// [2] override default margins from .news-list .image_container/.float_left/.float_right
.news-list--hero {
  margin-top: 0;
  margin-bottom: 0;
  min-height: 90vh; // [1]
  min-height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [1]
  
  .layout_latest:after {
    display: none;
  }
  
  > * {
    height: 100%;
    min-height: inherit;
    display: flex;
  }
  
  h1 {
    line-height: 1.25;
  }
  
  .image_container,
  .float_left,
  .float_right,
  .float_above,
  .float_below {
    margin-top: 0; // [2]
    margin-bottom: 0; // [2]
    margin-left: 0; // [2]
    margin-right: 0; // [2]
  }
  
  .hero__image .image_container {
    background-color: $news-hero-background-color;
    max-width: none;
  }
  
  .hero__image .image_container:not(.compat-object-fit) img {
    opacity: 0.3;
  }
  
  .hero__image .image_container.compat-object-fit {
    opacity: 0.3;
  }
  
  .hero__text {
    margin-top: $header-height;
    margin-bottom: $header-height;
    position: relative;
    z-index: 1000;
    color: #fff;
    text-align: left;
    
    a {
      color: #fff;
    }
    
    &.float_above {
      @include make-width(8, left);
    }
  
    &.float_below {
      @include make-width(8);
      
      align-self: flex-end;
    }
    
    &.float_left {
      margin-left: percentage(4/12);
    }
    
    &.float_right {
      margin-right: percentage(4/12);
    }
  }
  
  .info {
    font-weight: 700;
    font-family: $base-font-family-headers;
  }
  
  .teaser {
    font-size: 1.25rem;
    line-height: 1.6;
  }
  
  .more {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.news-full {
  .info {
    font-weight: 700;
  }
  
  .ce_text:first-of-type {
    font-size: 1.125em;
    line-height: 1.6;
  }
  
  .back {
    font-family: $base-font-family-headers;
    
    a {
      font-weight: bold;
      display: block;  
      text-decoration: none;  
      
      &:before {
        content: "«";
        margin-right: $base-spacing-unit--sm / 2;
      }
    }
  } 
}

// [1] calculate height based on padding for mod_article, including fallback
// [2] IE11: IE needs a height for flexbox. Will be overwritten by min-height
.news-full--hero {
  @include wrapper-max(none);
  @include padding-default;
    
  overflow: visible;
  
  .layout_full {
    overflow: visible;
  }
  
  .hero {
	  background-color: $news-hero-background-color;
    color: #fff;
    position: relative;
    z-index: 0;
    overflow: hidden;
    height: 0; // [2]
    min-height: 80vh; // [1]
    min-height: calc(80vh - #{2 * $base-spacing-unit--lg}); // [1]
    
    margin-left: -1 * $grid__gutter--half;
    margin-right: -1 * $grid__gutter--half;
    padding-left: $grid__gutter--half;
    padding-right: $grid__gutter--half;
    margin-bottom: $base-spacing-unit--lg;
    display: flex;
    
    @if($ie9-support == true) {
      .ie9 & {
  	    height: calc(80vh - #{2 * $base-spacing-unit--lg}); // [2][3]
  	  }
    }
  }
  
  .hero__image .image_container img {
    opacity: 0.3;
  }
  
  .hero__image .image_container.compat-object-fit img {
	  opacity: 0;
  }
  
  .hero__image .image_container.compat-object-fit {
	  opacity: 0.3;
  }
  
  .text_container {
    @include make-width(9);
  }
  
  .hero__text {
    @include make-width(9);
    
    display: block;    
    margin-top: $header-height;
    margin-bottom: $header-height;
    position: relative;
    z-index: 1000;
    
    @if($ie9-support == true) {
	    .ie9 & {
		    @include vertical-align();
		    
		    margin-top: 0;
		    margin-bottom: 0;
	    }
    }
    
    @include media-query(screen-md) {
      @include make-width(10);
    }
  }
  
  .back {
    @include make-width(9);
    
    font-family: $base-font-family-headers;
    
    a {
      @include media-query(screen-sm) {
        @include make-width(9);
      } 
      
      display: block;  
      text-decoration: none; 
      font-weight: bold; 
      
      &:before {
        content: "«";
        margin-right: $base-spacing-unit--xs;
      }
    }
  }
  
  .ce_comments {
    @include media-query(screen-sm) {
      @include make-width(9);
    } 
  }
}

.news-menu {
  
  > { 
    #{headings()} {
      @extend %h5;
      
      &:after {
        margin-top: $base-spacing-unit--xs;
      }
    }
  }

  a,
  .active {
    text-decoration: none;
    color: inherit;
    font-family: $headings__font-family;
  }
  
  .level_2 a,
  .level_2 span.active {
    vertical-align: middle;
    text-decoration: none;
    
    &:before {
      content: "»";
      margin-right: $base-spacing-unit--xs / 2;
    }
  }
    
  .year {
    > a,
    > .active {
      font-weight: $headings__font-weight;
    }
  }
}
