// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-color-background:                   	transparent;
$header-color-background--inverted:           $color-band-dark-background !default;
$header-color-background--inverted-gradient:  $color-band-dark-background-gradient !default;
$header-color-text--invert:                 	#fff;
$header-height:                     					6rem;
$footer-color-background:                     $color-blue;
$footer-color-text:                           #fff;

// USED FROM _layout.scss (nutshell)
// * $header-color-text [$color-text]

// USED FROM _variables.scss
// $base-spacing-unit, --sm, --lg
// $color-band-highlight-background
// $color-band-tint-background
// $color-band-dark-background-gradient
// $color-band-dark-background
// $color-band-dark-text

// --------------------------------------------------

@import "../../../nutshell/scss/base/layout";

// --------------------------------------------------

// nav--mobile--active
html.html--fixed {
  @include media-query(screen-offcanvas-max) {
    overflow-y: hidden;
    position: relative;
  }
}

// [1] sticky footer via flexbox, see https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
body {
	height: 100vh; // [1]
}

// #wrapper
// [1] sticky footer via flexbox 
[id="wrapper"] {
  display: flex; // [1]
  flex-direction: column; // [1]
  height: 100%; // [1]
}

// #header
[id="header"] {
  background: $header-color-background;
  position: absolute;
  z-index: 1001;
  left: 0;
  right: 0;
  height: $header-height;
  flex: none;
  color: $header-color-text;
  
  .inside {
    @include padding-default;
  }
  
  .header--relative & {
    position: relative;
  }
  
  .header--invert & {
	  color: $header-color-text--invert;
  }
  
  .header--background & {
	  background: $header-color-background--inverted;
    background: $header-color-background--inverted-gradient;
  }
}

// #container
// [1] sticky footer via flexbox 
// [2] IE10: sticky footer, fix gap between #container and #footer 
[id="container"] {
  flex: 1 0 auto; // [1]
  overflow: hidden; // [2]
}

// #main
.mod_article {
	padding-top:    $base-spacing-unit;
  padding-bottom: $base-spacing-unit;
  
  @include media-query(screen-lg) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-xl) {
    padding-top:    $base-spacing-unit--lg * 1.25;
    padding-bottom: $base-spacing-unit--lg * 1.25;
  }
  
  .inside {
	  > .last {
 	    margin-bottom: 0;
	  }
  }
}

.article--full {
  @include wrapper-max(none);
  
  padding: 0;
}

// #footer
[id="footer"] {	
  flex: none;
  background-color: $footer-color-background;
  color: $footer-color-text;
  letter-spacing: 1px;
  a {
    color: $footer-color-text;
  }

  .inside {
    padding-top: $base-spacing-unit--sm;
    padding-bottom: $base-spacing-unit--sm;
  }
}

// band
.band {
  @include wrapper-max(none);

  padding-left: 0;
  padding-right: 0;
  position: relative;
  
  .inside {
    @include wrapper-max();
    @include padding-default();
  }
}

.band--highlight {
  background-color: $color-band-highlight-background;
  color: #fff;
  
  a {
    color: inherit;
  }
}

.band--tint {
  background-color: $color-band-tint-background;
}

.band--dark {
  background: $color-band-dark-background;
  background: $color-band-dark-background-gradient;
  color: $color-band-dark-text;
}
