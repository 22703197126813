// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/PORTFOLIO
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-img-shadow
// $base-overlay-background


// USED FROM _grid.scss
// $grid__gutter - use the space horizontal and vertical


// --------------------------------------------------

// [1] prevent image shadow being cutted
// [2] make caption also clickable if there is a link around image (not working in <=IE10)
.portfolio {
  margin-bottom: $grid__gutter;
  font-weight: 700;
  font-family: $base-font-family-headers;
  overflow: visible; // [1]
  
  .image_container {
    position: relative;
    
    a::before {
      @include vertical-align();
      
      content: attr(title);
      position: absolute;
      z-index: 1;
      color: #fff;
      text-align: center;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s 0.1s;
      padding: $base-spacing-unit;
      pointer-events: none; // [2]
    }
    
    a:after {
      content: "";
      background: $base-overlay-background;
      display: block;
      vertical-align: middle;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }
    
    a {
      position: relative;
      z-index: 1;
      display: block;
    }
    
    img {
      box-shadow: $base-img-shadow;
    }
  }
  
  .caption { 
    text-align: center;
    width: 100%;
    padding: $base-spacing-unit--xs;
  }
  
  &:hover .image_container a:before,
  &:hover .image_container a:after {
    visibility: visible;
    opacity: 1;
  }
  
  &:hover .image_container a:before {
    transform: translateY(-40%);
  }
}

// [1] make caption also clickable if there is a link around image (not working in <=IE10)
.portfolio--hover {
  
  .image_container {
    position: relative;
    
    a:before {
      display: none;
    }
    
    &:after {
      content: "";
      background: $base-overlay-background;
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }
  }
  
  .caption { 
    @include vertical-align();
    
    position: absolute;
    z-index: 1;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 0.1s;
    padding: 0 $base-spacing-unit;
    pointer-events: none; // [1]
  }
  
  &:hover .image_container:after,
  &:hover .image_container a:after {
    visibility: visible;
    opacity: 1;
  }
  
  &:hover .caption {
    opacity: 1;
    visibility: visible;
    transform: translateY(-40%);
  }
}
