// *
// * Erdmann & Freunde
// * SIRIUS Contao Theme
// * erdmann-freunde.de/themes/sirius/
// *

// sass-lint:disable-all
//
// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$font-path: '../fonts/';

// --------------------------------------------------

/* yanone-kaffeesatz-200 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 200;
  src: url('#{$font-path}yanone-kaffeesatz-v11-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Yanone Kaffeesatz ExtraLight'), local('YanoneKaffeesatz-ExtraLight'),
       url('#{$font-path}yanone-kaffeesatz-v11-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-200.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-200.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}

/* yanone-kaffeesatz-300 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}yanone-kaffeesatz-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Yanone Kaffeesatz Light'), local('YanoneKaffeesatz-Light'),
       url('#{$font-path}yanone-kaffeesatz-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-300.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}

/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}yanone-kaffeesatz-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Yanone Kaffeesatz Regular'), local('YanoneKaffeesatz-Regular'),
       url('#{$font-path}yanone-kaffeesatz-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-regular.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}

/* yanone-kaffeesatz-700 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}yanone-kaffeesatz-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Yanone Kaffeesatz Bold'), local('YanoneKaffeesatz-Bold'),
       url('#{$font-path}yanone-kaffeesatz-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}yanone-kaffeesatz-v11-latin-700.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}


/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('#{$font-path}roboto-v18-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('#{$font-path}roboto-v18-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-100.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('#{$font-path}roboto-v18-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
       url('#{$font-path}roboto-v18-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('#{$font-path}roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('#{$font-path}roboto-v18-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
       url('#{$font-path}roboto-v18-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('#{$font-path}roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('#{$font-path}roboto-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('#{$font-path}roboto-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('#{$font-path}roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-500.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('#{$font-path}roboto-v18-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
       url('#{$font-path}roboto-v18-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('#{$font-path}roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('#{$font-path}roboto-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
       url('#{$font-path}roboto-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('#{$font-path}roboto-v18-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
       url('#{$font-path}roboto-v18-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-900.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('#{$font-path}roboto-v18-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
       url('#{$font-path}roboto-v18-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-path}roboto-v18-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-path}roboto-v18-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-path}roboto-v18-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-path}roboto-v18-latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

