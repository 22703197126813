// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// BASE/SHARED
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/base/shared";

// --------------------------------------------------

// PUT YOUR OWN BASIC STYLES HERE

// TERMINVORSCHAU
.ce_text.tint {
  background-color: $color-tint;
  h3 {
    padding-top: $base-spacing-unit/4;
    padding-right: $base-spacing-unit/2;
    padding-bottom: $base-spacing-unit/4;
    padding-left: $base-spacing-unit;
    margin-bottom: $base-spacing-unit/2;
    background-color: $color-tint;
    border-bottom: $base-border-width solid $color-blue;
  }
  h4 {
    margin: 0;
    padding-top: $base-spacing-unit/4;
    padding-right: $base-spacing-unit/2;
    padding-bottom: $base-spacing-unit/4;
    padding-left: $base-spacing-unit;
    background: url("/files/theme/dist/img/logo_icon.png") no-repeat 8px center;
  }
  p {
    margin-bottom: $base-spacing-unit/2;
    padding-top: 0;
    padding-right: $base-spacing-unit;
    padding-bottom: $base-spacing-unit/2;
    padding-left: $base-spacing-unit;
    border-bottom: 1px solid $color-tint;
    font-size: $base-font-size--xs;
  }
}

// NEWSLETTERKASTL

// Energieausgleich
p.ausgleich {
  padding: 0.5em 0.5rem;
  background-color: $color-gold-light;
  color: $color-gold;
  border-bottom: 2px dotted;
}
